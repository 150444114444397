import React from "react";

import { TextStyled } from "components/Text/Text.Styles";
import { CallBadgeStyle, CallBadgeDividerStyle } from "components/LeadManagement/IncomingCallInfo/IncomingCallInfoComponent.Styles";
import { graceGray600, uiTextError } from "styleConstants";

interface IncomingCallInfoComponentProps {
  callNumber: string;
  firstName?: string;
  lastName?: string;
  customerId?: number;
  subscriptionId?: number;
  emailAddress?: string;
}

const CallBadge = (
  props: IncomingCallInfoComponentProps,
): React.JSX.Element => {
  const formatCallNumber = (number: string) => {
    return `(${number.slice(0, 3)}) ${number.slice(3, 6)} - ${number.slice(6)}`;
  };

  return (
    <>
      {props.callNumber ? (
        <CallBadgeStyle data-testid="call-badge">
          <TextStyled fontSize="lg" fontWeight="thin">
            {props.firstName && props.lastName
              ? `${props.firstName} ${props.lastName}`
              : "Incoming Call"}
          </TextStyled>
          <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
          <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
              {formatCallNumber(props.callNumber)}
          </TextStyled>

          {props.emailAddress ?
            <>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
              <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
                  {props.emailAddress}
              </TextStyled>
            </>
            :
            <>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
              <TextStyled color={uiTextError} fontWeight="bold" fontSize="md">
                  NO EMAIL
              </TextStyled>
            </>
          }     
                       
          {props.customerId !== 0 &&
            <>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle> 
              <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
                {"Customer ID: "}{props.customerId}
              </TextStyled>
              <CallBadgeDividerStyle>.</CallBadgeDividerStyle>
              <TextStyled color={graceGray600} fontWeight="thin" fontSize="md">
                {"Subscription ID: "}{props.subscriptionId}
              </TextStyled>
            </>
          }                  
        </CallBadgeStyle>
      ) : null}
    </>
  );
};

export default CallBadge;
