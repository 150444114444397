import * as React from "react";
import { LeadManagementMenuStyled } from "./LeadManagementMenu.Styles";
import MenuRadioItem from "./../MenuRadioComponent/MenuRadioItem";
import { FORM_STATES } from "./LeadManagement";

interface LeadManagementMenuProps {
  onChange: (s: FORM_STATES) => void;
  step: FORM_STATES;
  menuList: { label: string; step: FORM_STATES }[];
  completedSteps: FORM_STATES[];
}

const LeadManagementMenu = (
  props: LeadManagementMenuProps,
): React.JSX.Element => {
  return (
    <LeadManagementMenuStyled data-testid="lead-management-menu">
      <div className="left-menu__radio-wrapper">
        {props.menuList.map((item, i) => {
          const isComplete = props.completedSteps.includes(item.step);
          const isPreviousComplete = item.step - 1 >= 0 ? props.completedSteps.includes(item.step - 1) : true;
          return (
            <MenuRadioItem
              key={i}
              onClick={() => props.onChange(item.step)}
              label={item.label}
              isCompleted={props.completedSteps.includes(item.step)}
              isActive={props.step === item.step}
              isDisabled={!isComplete && !isPreviousComplete}
            />
          );
        })}
      </div>
    </LeadManagementMenuStyled>
  );
};

export default LeadManagementMenu;
