import styled from "@emotion/styled";

import {
  bluePrimary,
  basePageHoverBg,
  baseGray200,
} from "./../../styleConstants";

export const MenuRadioItemStyle = styled.div`
  display: flex;
  padding: 10px 0 10px 14px;
  margin: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;

  &:hover {
    background-color: ${basePageHoverBg};
  }

  p:first-of-type {
    text-transform: uppercase;
  }
`;

interface MenuRadioButtonProps {
  isCompleted?: boolean;
  isActive?: boolean;
}

export const MenuRadioButton = styled.div<MenuRadioButtonProps>`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 2px solid
    ${(props) =>
      props.isActive
        ? bluePrimary
        : props.isCompleted
        ? bluePrimary
        : baseGray200};
  border-width: ${(props) => (props.isCompleted ? 0 : 2)}px;
  box-sizing: border-box;
  position: relative;
  margin-right: 16px;

  .menu-ralio-item {
    &__check {
      background: url("../images/checkboxMark.svg");
      width: 20px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: all ease-in-out 0.2s;
      opacity: ${(props) => (props.isCompleted ? 1 : 0)};
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__inner {
      transition: all ease-in-out 0.2s;
      border-radius: 50%;
      background: ${bluePrimary};
      width: ${(props) => (props.isCompleted ? "100%" : "10px")};
      height: ${(props) => (props.isCompleted ? "100%" : "10px")};
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: ${(props) => (props.isActive ? 1 : props.isCompleted ? 1 : 0)};
    }
  }
`;
