import { Lead } from "atoms/lead";
import { ButtonAmSpot, ButtonNaSpot, ButtonPmSpot } from "components/Buttons/Button.Styled";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { TextStyled } from "components/Text/Text.Styles";
import React from "react";
import { getWeekdayName } from "utils/getWeekday";
import { LeadBillingInfo, LeadPlanInfo, LeadSchedulingInfo } from "../LeadManagement";

interface ScheduleAppointmentProps {
    date: {
        additions?: any[];
        default?: boolean; 
        value: any; 
        label: string,
    }
    selectedDate: any,
    setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
    eventId?: string;
    setData: React.Dispatch<React.SetStateAction<Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo>>;
    data: any;
    scheduledDate?: any;
    scheduledWindow?: any;
    setScheduledDate?: React.Dispatch<React.SetStateAction<any>>;
    setScheduledWindow?: React.Dispatch<React.SetStateAction<any>>;
    isAmSelected?: boolean;
    isPmSelected?: boolean;
    setIsAmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScheduleAppointment = (props: ScheduleAppointmentProps) =>{
    const checkAvailability = (date: {
        additions?: any[]; default?: boolean; value: any; label: string }, window: string): boolean => {
        return date.additions?.some(item => item.window === window) ?? false;
    };


    const handleAmClick = (date: any) => {
        props.setSelectedDate(date);
        if(props.setScheduledWindow) {
            props.setScheduledWindow('');
        }
        const scheduleDate = {...date, window: 'AM'};
        props.setData(prevData => ({...prevData, ...scheduleDate}));
        
        if(props.setIsAmSelected !== undefined && props.setIsPmSelected !== undefined) {
            if(props.isAmSelected === false) {
                props.setIsAmSelected(!props.isAmSelected);
                props.isPmSelected && props.setIsPmSelected(!props.isPmSelected);
            }
        }
    };
    const handlePmClick = (date: any) => {        
        props.setSelectedDate(date);
        if(props.setScheduledWindow) {
            props.setScheduledWindow('');
        }
        props.setData(prevData => ({...prevData, ...date, window: 'PM'}));
        props.setData({...props.data, window: 'PM', ...date});
        if(props.setIsAmSelected !== undefined && props.setIsPmSelected !== undefined) {
            if(props.isPmSelected === false) {
                props.setIsPmSelected(!props.isPmSelected);
                props.isAmSelected && props.setIsAmSelected(!props.isAmSelected);
            }
        }
    };
    return (
        <div key={props.date?.label} style={{marginRight: "10px"}} >
            <div className="spot-card">
            {props.date?.additions && (
                <>  
                    <FlexContainer column={true} gap={1}>
                        <TextStyled fontSize="14">{getWeekdayName(props.date.value)}</TextStyled>
                        <TextStyled fontSize="14" style={{marginBottom: '10px'}}>{props.date.value.substr(-2)}</TextStyled>
                    </FlexContainer>
                    
                    <FlexContainer column={true} gap={10}>
                        {checkAvailability(props.date, "AM") ? (
                            props.data?.appointment?.success === true ? 
                            <ButtonAmSpot onClick={() => handleAmClick(props.date)} isAmClicked={(props.selectedDate?.value===props.date.value && props.isAmSelected) || (props.scheduledDate === props.date.value && props.scheduledWindow === "AM")}>
                            AM
                            </ButtonAmSpot> : <ButtonAmSpot onClick={() => handleAmClick(props.date)} isAmClicked={props.selectedDate?.value===props.date.value && props.isAmSelected}>
                            AM
                            </ButtonAmSpot>
                        ): (
                            <ButtonNaSpot disabled>NA</ButtonNaSpot>
                        )}
                        {checkAvailability(props.date, "PM") ? 
                        (
                            props.data?.appointment?.success === true ? 
                            <ButtonPmSpot onClick={() => handlePmClick(props.date)} isPmClicked={(props.selectedDate?.value ===props.date.value && props.isPmSelected) || (props.scheduledDate === props.date.value && props.scheduledWindow === "PM")}>
                            PM
                            </ButtonPmSpot> : <ButtonPmSpot onClick={() => handlePmClick(props.date)} isPmClicked={props.selectedDate?.value===props.date.value && props.isPmSelected}>
                            PM
                            </ButtonPmSpot>
                        ) : (
                            <ButtonNaSpot disabled>NA</ButtonNaSpot>
                        )}
                    </FlexContainer>
                    
                </>
            )
            
            }
            </div>
        </div>
    );
};
