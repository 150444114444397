import React, { useEffect, useState } from "react";

import LeadInfoForm from "../LeadMainInfo/LeadInfoForm";
import { Lead } from "./../../../atoms/lead";
import getAvailableSpots from "../../../api/getAvailableSpots";
import { modifySpots } from "utils/modifySpots";

interface SchedulingInfoProps {
  closer?: boolean;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: any;
  errors: string[];
  eventId?: string;
  isAmSelected?: boolean;
  isPmSelected?: boolean;
  setIsAmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  isCancelApt?: boolean,
  step?: number,
  cancelApt?: any
}

export const groupBy = (items: any[], key: string) =>
items?.reduce(
  (result: any, item: any) => ({
    ...result,
    [item[key]]: [...(result[item[key]] || []), item],
  }),
  {},
);

const getFormFields = (disabled: boolean, spots?: any, time?: any) => [    
  {
    label: "Select a date",
    component: "dateInput",
    placeholder: "DD/MM/YYYY",
    name: "date",
    additionalClassName: "_p-right dateInput",
    mask: "99/ 99/ 9999",
    options: spots || [],
  },
  {
    label: "Select a time",
    component: "timeSelect",
    placeholder: "Dropdown",
    name: "time",
    options: time || [],
    additionalClassName: "_p-left",
    disabled: disabled,
  },
];

const SchedulingInfo = (props: SchedulingInfoProps): React.JSX.Element => {
  const [formFields, setFormFields] = useState(getFormFields(true));
  const [optionsForSelect, setOptionForSelect] = useState<any[]>([]);
  const [optionsForTime, setOptionForTime] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [loadingSpots, setLoadingSpots] = useState(false);
  useEffect(() => {
    props.setData((prev) => ({
      ...prev,
      time: "",
      isAroSpot: null,
      spotID: null,
      window: null,
    }));
    const selectedInfo = optionsForSelect.find(
      (o) => o.value === props.data.date,
    );

    if ((selectedInfo as any)?.additions) {
      const times = groupBy((selectedInfo as any).additions, "window");
      const timeRanges = Object.keys(times).map((t) => {
        return {
          value: t,
          label: t,
          additions: times[t][0],
        };
      });
      setOptionForTime(timeRanges);
      setFormFields(
        getFormFields(!props.data.date?.length, optionsForSelect, timeRanges),
      );
    } else {
      setFormFields(getFormFields(!props.data.date?.length, optionsForSelect));
    }
  }, [props.data.date, optionsForSelect]);

  useEffect(() => {
    if (props.data.time) {
      const appointmentInfo = optionsForTime.filter(
        (o) => o.value === props.data.time,
      )[0]?.additions;
      props.setData((prev) => ({ ...prev, ...appointmentInfo }));
    } else {
      props.setData((prev) => ({
        ...prev,
        isAroSpot: null,
        spotID: null,
        window: null,
      }));
    }
  }, [props.data.time, optionsForTime]);

  useEffect(() => {
    (async () => {
      setLoadingSpots(true);
      const res = await getAvailableSpots(page, props.eventId);
      
      if(res.data !== undefined) {
        const dates = res.data.dates;
        const spots = groupBy(res.data.spots, "date");
        const modifiedSpots = modifySpots(dates, spots);
        
        const spotsForSelect = [];
  
        for (const [key, value] of Object.entries(modifiedSpots)) {
          spotsForSelect.push({
            label: key,
            value: key,
            additions: value,
          });
        }
        setOptionForSelect(spotsForSelect);
        setFormFields(getFormFields(true, spotsForSelect));
        setLoadingSpots(false);
      } 
    })();
  }, [page, props.data?.appointment]);
  formFields.forEach(formField => {
    formField.options.map((spot: { value: any; additions: { window: string; }[]; })=> (spot.value === props.data.appointment?.appointment?.date && spot.additions.push({window: Number(props.data.appointment?.appointment?.start?.slice(0,2)) >= 12 ? 'PM' : 'AM'})));
  });
  
  return <LeadInfoForm {...props} formFields={formFields} page={page} setPage={setPage} loadingSpots={loadingSpots} eventId={props.eventId} setData={props.setData} data={props.data} isAmSelected={props.isAmSelected} isPmSelected={props.isPmSelected} setIsAmSelected={props.setIsAmSelected} setIsPmSelected={props.setIsPmSelected} />;
};

export default SchedulingInfo;
