import React from "react";

import { LeadBarStyled } from "./LeadBar.Styles";
import { FlexContainer } from "./../Flex/Flex.Styles";

import CallBadge from "./IncomingCallInfo/CallBadge";
import { useRecoilValue } from "recoil";
import { leadAtom } from "atoms/lead";
import Search from "./../Search/Search";
import CreateLead from "./../CreateLead/CreateLead";

const LeadBar = (): React.JSX.Element => {
  const leadData: any = useRecoilValue(leadAtom);

  return (
    <LeadBarStyled data-testid="lead-bar">
      <FlexContainer
        className="lead-modal__header"
        justifyContent="space-between"
      >
        <div>
          <CallBadge
            firstName={leadData?.name_first}
            lastName={leadData?.name_last}
            callNumber={leadData?.phone_number}
            customerId={leadData?.pr_customer_id}
            emailAddress={leadData?.email_address}
            subscriptionId={leadData?.pr_subscription_id}
          />
        </div>
        <FlexContainer gap={10} justifyContent="space-between">
          <CreateLead />
          <Search />
        </FlexContainer>
      </FlexContainer>
    </LeadBarStyled>
  );
};

export default LeadBar;
