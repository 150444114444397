import styled from "@emotion/styled";
import {
  baseGray200,
  graceGray900,
  graceGray600,
  placeholderColor,
  basePageHoverBg,
  baseWhite,
  uiGray50,
} from "styleConstants";

export const SelectStyled = styled.div`
  background: ${baseWhite};

  .select {
    &__field {
      border-radius: 6px;
      padding: 10px 13px;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      border: 1px solid ${baseGray200};
      color: ${graceGray900};
      transition: all ease-in-out 0.2s;
      position: relative;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 50px;
      text-align: left;

      &._disabled {
        background: ${uiGray50};
        cursor: default;
      }

      &__tags {
        display: flex;
        flex-wrap: wrap;
        margin-top: -5px;

        &__tag {
          padding: 2px 10px;
          margin-top: 5px;
          border-radius: 6px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          background: ${basePageHoverBg};

          &__cross {
            background: url("../images/CrossDark.svg");
            width: 14px;
            height: 14px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 4px;
          }
        }
      }

      &::after {
        content: url("../images/ArrowDown.svg");
        display: block;
        position: absolute;
        right: 16px;
        top: 9px;
      }
    }

    &__placeholder {
      color: ${placeholderColor};
      height: 17px;
      display: block;
      text-align: left;
    }

    &__sub__menu {
      position: absolute;
      background: white;
      left: 100%;
      overflow: auto;
      max-height: 210px;
      z-index: 100;
      border: 1px solid ${baseGray200};
      border-radius: 6px;
      top: 0;
      width: 100%;
      box-sizing: content-box;
    }

    &__menu {
      min-height: 32px;
      padding: 4px 0;
      border-radius: 8px;
      border: 1px solid ${baseGray200};
      background: ${baseWhite};
      width: 100%;
      top: 0;
      max-height: 200px;
      overflow: auto;
      z-index: 9999;
      right: 0;
      box-sizing: content-box;

      &__wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: start;
        height: fit-content;
      }

      &__option {
        transition: all ease-in-out 0.2s;
        padding: 10px 16px;
        position: relative;
        display: flex;

        &._single {
          justify-content: space-between;
        }

        &:not(:last-child) {
          border-bottom: 1px solid ${baseGray200};
        }

        &:hover {
          background-color: ${basePageHoverBg};
          cursor: pointer;
        }

        &__tick {
          content: url("../images/Check.svg");
          display: block;
          // right: 8px;
          // top: 8px;
          // position: absolute;
          opacity: 0;
          transition: all ease-in-out 0.1s;
        }

        &._group {
          &::after {
            content: url("../images/ArrowDownGray.svg");
            transform: rotate(-90deg);
            display: block;
            position: absolute;
            right: 8px;
            top: 6px;
            opacity: 1;
            transition: all ease-in-out 0.15s;
          }

          &._opened {
            &::after {
              transform: rotate(0deg);
              top: 8px;
            }
          }
        }

        &._multi {
          p {
            margin-left: 28px;
          }
        }

        &._is-checked._single {
          .select__menu__option__tick {
            opacity: 1;
          }

          p {
            color: ${graceGray900};
          }
        }

        p {
          color: ${graceGray600};
        }
      }
    }
  }
`;
