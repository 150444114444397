import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { NotesStyles } from "./Notes.Styles";
import createNote from "./../../api/createNote";
import { formatDate } from "./../../utils/utils";
import { loadingAtom } from "./../../atoms/loading";
import { leadAtom, Lead } from "./../../atoms/lead";

import IconButton from "./../Buttons/IconButton";
import SectionHeader from "./../SectionHeader/SectionHeader";
import { FormField } from "./../Form/Input/Input.Styles";

interface Note {
  created_at: string;
  created_by_username: string;
  note: string;
  system_id: number;
}
interface NoteProps {
  note: Note;
}


export const Note = (props: NoteProps): React.JSX.Element => (
  <div className="note-container" data-testid="note-container">
    <div className="note-rep-name">{props.note.created_by_username}</div>
    <div className="note-date">{formatDate(props.note.created_at)}</div>
    <div className="note">{props.note.note}</div>
  </div>
);

interface NotesInputProps {
  submit: (e: React.FormEvent<HTMLFormElement>) => void;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  submitOnEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const NotesInput = (props: NotesInputProps): React.JSX.Element => {
  const [focused, setFocused] = useState(false);

  return (
    <div className="add-note-container" data-testid="add-note-container">
      <FormField>
        <form
          onSubmit={props.submit}
          className={`add-note-input__wrapper ${focused ? "_focused" : ""}`}
        >
          <textarea
            className="add-note-input"
            name="newComment"
            id="newComment"
            value={props.note}
            rows={4}
            onChange={(e) => props.setNote(e.target.value)}
            onKeyDown={props.submitOnEnter}
            placeholder="Write a note..."
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
          />

          <IconButton
            type="submit"
            className={props.note ? "add-note-filled-button" : "add-note-submit-button"}
            icon={props.note ? "PaperAirplane-filled" : "PaperAirplane"}
            tooltip="Submit"
          />
        </form>
      </FormField>
    </div>
  );
};

export const getNotes = (notes: Note[]) =>
  notes &&
  notes.map((thisNote: Note, key: number) => (
    <Note note={thisNote} key={key} />
  ));

const Notes = (): React.JSX.Element => {
  let { leadId } = useParams();
  const notesContainerRef = useRef<HTMLDivElement>(null);
  const leadData = useRecoilValue(leadAtom);
  const setLoading = useSetRecoilState(loadingAtom);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState("");

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveNote();
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey == false) {
      saveNote();
    }
  };

  const saveNote = async () => {
    try {
      setLoading(true);
      const response = await createNote(leadId, newNote);
      const noteToAdd: Note = response.data;
      setNotes([noteToAdd, ...notes]);
      setNewNote("");

      if (notesContainerRef && notesContainerRef.current) {
        notesContainerRef.current.scroll && notesContainerRef.current.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    let noteHistory = leadData ? (leadData as Lead).notes : [];
    setNotes(noteHistory);
  }, [leadData]);

  return (
    <NotesStyles className="notes-parent-container" data-testid="notes-parent-container">
      <SectionHeader label="Notes" icon="Pencil" />
      <div ref={notesContainerRef} className="note-list-container">
        {getNotes(notes)}
      </div>
      <NotesInput
        note={newNote}
        setNote={setNewNote}
        submit={submit}
        submitOnEnter={submitOnEnter}
      />
    </NotesStyles>
  );
};

export default Notes;
