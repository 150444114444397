import React, { useState, useEffect, useRef } from "react";

import { SelectStyled } from "./Select.Styles";
import { TextStyled } from "../../Text/Text.Styles";
import Checkbox from "../Checkbox/Checkbox";

export interface OptionType {
  value: string;
  label: string;
}

interface SelectProps {
  options: (OptionType & { options?: OptionType[] })[];
  isMulti?: boolean;
  placeholder?: string;
  onChange: (e: any) => void;
  value: any;
  isGroupable?: boolean;
  withTags?: boolean;
  disabled?: boolean;
}

interface SelectOptionProps {
  isMulti?: boolean;
  selectedValues: any[];
  isGroupable?: boolean;
  addSelectedValue: (selectValue: any, val: any) => void;
  option: OptionType & { options?: OptionType[] };
  selectValue: any;
  withTags?: boolean;
  key: number;
}

const SelectOption = (props: SelectOptionProps): React.JSX.Element => {
  const [isOpenedValue, setValueOpened] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  //fix to show only one suboption menu at once
  const useClickOutside = (ref: any, onClickOutside: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          itemRef.current &&
          !itemRef.current.contains(event.target)
        ) {
          onClickOutside();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      //fix for showing select container outside of overflow: auto (do not delete)
      document.addEventListener("scroll", handleClickOutside, true);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("scroll", handleClickOutside, true);
      };
    }, [ref, onClickOutside]);
  };

  useClickOutside(wrapperRef, () => {
    setValueOpened(false);
  });

  useEffect(() => {
    const main = document.getElementById("main-container");

    if (isOpenedValue && wrapperRef.current) {
      const doubleBorder = 2;
      const mainHeight = main?.getBoundingClientRect().height || 0;
      const mainWidth = main?.getBoundingClientRect().width || 0;

      const menuTop =
        (wrapperRef.current?.getBoundingClientRect().top || 0) -
        (main?.getBoundingClientRect().top || 0) -
        doubleBorder;
      const menuLeft =
        (wrapperRef.current?.getBoundingClientRect().left || 0) -
        (main?.getBoundingClientRect().left || 0) -
        doubleBorder / 2;

      const menuHeight =
        wrapperRef.current?.getBoundingClientRect().height || 0;
      const menuWidth = wrapperRef.current?.getBoundingClientRect().width || 0;
      const distanceY = mainHeight - menuTop - menuHeight;
      const distanceX = mainWidth - menuLeft - menuWidth;

      if (distanceY < 0) {
        wrapperRef.current.style.top = `${distanceY - doubleBorder}px`;
      }
      if (distanceX < 0) {
        wrapperRef.current.style.right = "100%";
        wrapperRef.current.style.left = "unset";
      }
    }
  }, [wrapperRef.current, isOpenedValue]);

  return (
    <>
      <div
        ref={itemRef}
        className={`select__menu__option  ${
          props.isMulti ? "_multi" : "_single"
        } ${
          props.selectedValues.includes(props.option.value.toString())
            ? "_is-checked"
            : ""
        } ${
          props.isGroupable && props.option.options?.length ? "_group" : ""
        } ${isOpenedValue ? "_opened" : ""}`}
        onClick={() => {
          props.isGroupable && props.option.options?.length
            ? setValueOpened((prev) => !prev)
            : props.addSelectedValue(props.selectValue, props.option.value);
        }}
      >
        {props.isMulti && (
          <Checkbox
            onClick={() => {
              props.addSelectedValue(props.selectValue, props.option.value);
            }}
            checked={props.selectedValues.includes(
              props.option.value.toString(),
            )}
          />
        )}
        <TextStyled fontWeight="thin">{props.option.label}</TextStyled>
        {!props.isMulti &&
          props.selectedValues.includes(props.option.value.toString()) && (
            <div className="select__menu__option__tick"></div>
          )}
      </div>

      {isOpenedValue && (
        <div className="select__sub__menu" ref={wrapperRef}>
          {props.option.options?.map((subOption, ind) => (
            <div
              className={`select__menu__option _sub _single ${
                props.selectedValues.includes(subOption.value.toString())
                  ? "_is-checked"
                  : ""
              }`}
              key={ind}
              onClick={() =>
                props.addSelectedValue(props.selectValue, subOption.value)
              }
            >
              <TextStyled fontWeight="thin">{subOption.label}</TextStyled>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const Select = (props: SelectProps): React.JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  const addSelectedValue = (selectValue: string, value: string) => {
    const arr = selectValue?.length ? selectValue.split(",") : [];
    if (props.isMulti) {
      let newArray = [...arr];

      if (newArray.includes(value)) {
        newArray = newArray.filter((item) => item != value);
      } else {
        newArray.push(value);
      }

      props.onChange(newArray.toString());
    } else {
      if (arr.length && arr[0] === value) {
        props.onChange([].toString());
      } else {
        props.onChange([value].toString());
      }
      setOpen(false);
    }
  };

  const useClickOutside = (ref: any, onClickOutside: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      //fix for showing select container outside of overflow: auto (do not delete)
      document.addEventListener("scroll", handleClickOutside, true);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("scroll", handleClickOutside, true);
      };
    }, [ref, onClickOutside]);
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const getSelectedItems = () => {
    const options = [...props.options];
    props.options.forEach((o) =>
      o.options?.length ? options.push(...o.options) : null,
    );

    return options.filter((o) => selectedValues.includes(o.value));
  };

  const getSelectedItemsForText = () => {
    return getSelectedItems()
      .map((o) => o.label)
      .join(", ");
  };

  useEffect(() => {
    if(props.value) {
      if(props.isMulti) {
        setSelectedValues(props.value.split(','));
      } else {                
          props.options.forEach((option) => {
            if(option.value == props.value) {
              setSelectedValues([option.value]);
              return;
            }
          });
      }
    } else {
      setSelectedValues([]);
    }

    /*if(props.value) {
      const values = props.value.split(",");
      const newSelectedValues: string[] = [];
      values.forEach((value) => {
        props.options.forEach((option) => {
          if(option.value === value) {
            newSelectedValues.push(value);
          }
        });
      });

      setSelectedValues(newSelectedValues);
    } else {
      setSelectedValues([]);
    }*/    
  }, [props.value]);

  //fix for showing select container outside of overflow: auto
  useEffect(() => {
    const main = document.getElementById("main-container");

    if (open && menuRef.current) {
      const doubleBorder = 2;
      const mainHeight = main?.getBoundingClientRect().height || 0;

      const menuTop =
        (wrapperRef.current?.getBoundingClientRect().top || 0) -
        (main?.getBoundingClientRect().top || 0) -
        doubleBorder;
      const menuLeft =
        (wrapperRef.current?.getBoundingClientRect().left || 0) -
        (main?.getBoundingClientRect().left || 0) -
        doubleBorder / 2;

      menuRef.current.style.top = `${menuTop}px`;
      menuRef.current.style.left = `${menuLeft}px`;
      menuRef.current.style.width = `${
        wrapperRef.current?.getBoundingClientRect().width || 0
      }px`;

      const menuHeight = menuRef.current?.getBoundingClientRect().height || 0;
      const distanceY = mainHeight - menuTop - menuHeight;
      if (distanceY < 0) {
        menuRef.current.style.top = `${menuTop + distanceY - doubleBorder}px`;
      }
    }
  }, [menuRef.current, open]);

  return (
    <SelectStyled
      data-testid="select"
      className={`select__wrapper`}
      ref={wrapperRef}
    >
      <div
        className={`select__field ${props.disabled ? "_disabled" : ""}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!props.disabled) {
            setOpen(true);
          }
        }}
      >
        {selectedValues.length ? (
          <span>
            {props.withTags ? (
              <div className="select__field__tags">
                {getSelectedItems().map((item, i) => (
                  <div key={i} className="select__field__tags__tag">
                    <TextStyled fontSize="md">{item.label}</TextStyled>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        addSelectedValue(props.value, item.value);
                      }}
                      className="select__field__tags__tag__cross"
                    ></div>
                  </div>
                ))}
              </div>
            ) : (
              getSelectedItemsForText()
            )}
          </span>
        ) : (
          <span className="select__placeholder">{props.placeholder}</span>
        )}
      </div>
      {open ? (
        <div ref={menuRef} className="select__menu__wrapper">
          <div className="select__menu">
            {props.options.map((o, i) => {
              return (
                <SelectOption
                  selectedValues={selectedValues}
                  addSelectedValue={addSelectedValue}
                  key={i}
                  isGroupable={props.isGroupable}
                  selectValue={props.value}
                  option={o}
                  isMulti={props.isMulti}
                  withTags={props.withTags}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </SelectStyled>
  );
};

export default Select;
