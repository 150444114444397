import React from "react";

import { SectionHeaderStyles } from "./SectionHeader.Styles";
import { SectionBannerStyled } from "./SectionBanner.Styles";

interface SectionHeaderProps {
  label: string;
  icon: string;
  monthlyBanner?: boolean;  
}

export const getContentTemplate = (icon: string) =>
  `url("../images/${icon}.svg")`;

const SectionHeader = (props: SectionHeaderProps): React.JSX.Element => {
  return (
    <SectionHeaderStyles>
      <div className="container" data-testid="section-header-container">
        <div className="header-icon-container">
          <div
            className="header-icon"
            style={{ content: getContentTemplate(props.icon) }}
          />
        </div>
        <div className="header-label">{props.label}</div>
      </div>
      
      {props.monthlyBanner && 
        <SectionBannerStyled>                  
            Monthly Billing is the only supported payment plan in this area               
        </SectionBannerStyled>
      }
    </SectionHeaderStyles>
  );
};

export default SectionHeader;
