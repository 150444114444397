import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useFeatureFlag } from "configcat-react";
import { useNavigate } from "react-router-dom";

import createLead from "./../../api/createLead";
import { updateLead } from "./../../api/updateLead";
import { createCustomer } from "./../../api/createCustomer";
import { billing } from "./../../api/billing";

import { leadAtom, Lead } from "./../../atoms/lead";
import { plansAtom } from "./../../atoms/plans";
import { employeeAtom, Employee } from "./../../atoms/employee";
import { errorBannerAtom } from "./../../atoms/errorBanner";

import { loadingAtom } from "./../../atoms/loading";
import getLead from "./../../api/getLead";
import getPlans from "./../../api/getPlans";

import PlanInfo from "./../../components/LeadManagement/PlanInfo/PlanInfo";
import BillingInfo from "./BillingInfo/BillingInfo";
import SchedulingInfo from "./SchedulingInfo/SchedulingInfo";
import Spinner from "./../../components/Spinner/Spinner";
import { FlexContainer } from "./../../components/Flex/Flex.Styles";
import LeadManagementMenu from "./../../components/LeadManagement/LeadManagementMenu";
import Dispositions from "./../../components/Dispositions/Dispositions";
import Notes from "./../../components/Notes/Notes";
import LeadMainInfo from "./../../components/LeadManagement/LeadMainInfo/LeadMainInfo";
import {
  LeadManagementStyled,
  LeadActionButtonsStyles,
} from "./LeadManagement.Styles";

import LeadBar from "./LeadBar";
import ErrorBanner from "./ErrorBanner";

import Notification from "./../Notification/Notification";
import {
  ButtonSecondary,
  ButtonPrimary,
} from "components/Buttons/Button.Styled";
import SectionHeader from "./../SectionHeader/SectionHeader";
import { scheduling } from "api/schedule";
import { getAppointement } from "api/getAppointment";
import { rescheduling } from "api/rescheduling";
import { cancelAppointement } from "api/cancelApppointment";

export enum FORM_STATES {
  personalInfo,
  planInformation,
  billingInformation,
  schedule,
  notes,
  disposition,
}

interface LeadManagementProps {
  closer?: boolean;
  leadId?: string;
  outbound?: boolean;
  showMenu?: boolean;
  showBilling?: boolean;
  showSchedule?: boolean;
}

const setterMenu = [
  {
    label: "Personal info",
    step: FORM_STATES.personalInfo,
  },
];

export interface LeadPlanInfo {
  pr_event_id?: number;
  pr_customer_id?: number;
  pr_subscription_id?: number;
  pr_service_id?: number;
  pr_sold_by_id?: string;
  pr_source_id?: number;
  pr_service_charge?: number;
  pr_initial_charge?: number;
  pr_created_at?: string;
  pr_agreement_length?: number;
  pr_is_initial_appointment_completed?: boolean;
}

export interface LeadBillingInfo {
  ach_first_name?: string,
  ach_last_name?: string,
  ach_billing_address?: string,
  ach_billing_city?: string,
  ach_billing_state?: string,
  ach_billing_zip?: string,
  ach_billing_country?: string,
  ach_routing_number?: string,
  ach_account_number?: string,
  ach_auto_pay?: number,
  bank_name?: string,
}

export interface LeadSchedulingInfo {
  date?: any;
  time?: string;
  spotID?: any;
  window?: any;
  isAroSpot?: any;
  additions?: any;
}

export interface AppointmentResponseType {
  data: {
      success: boolean;
      customerId: string;
      appointment: {
          appoinmentId: string;
          date: string;
          start: string;
          appointmentType: string;
          appointmentTypeName: string;
      } | null;
  }
}

export const fieldsInitialData: Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo = {
  ach_first_name: "",
  ach_last_name: "",
  ach_billing_address: "",
  ach_billing_city: "",
  ach_billing_state: "",
  ach_billing_zip: "",
  ach_billing_country: "US",
  ach_routing_number: "",
  ach_account_number: "",
  ach_auto_pay: 0,
  bank_name: "",
  name_first: "",
  name_last: "",
  contact_id: 0,
  email_address: "",
  system_id: 0,
  phone_number: "",
  address_line_1: "",
  city: "",
  province: "",
  postal_code: "",
  pest_types: "",
  property_type: "",
  pr_event_id: 0,
  pr_customer_id: 0,
  pr_subscription_id: 0,
  pr_service_id: 0,
  pr_sold_by_id: "",
  pr_source_id: 0,
  pr_service_charge: 0,
  pr_initial_charge: 0,
  pr_created_at: "",
  pr_payment_profiles: [],
  service_type: "",
  pr_agreement_length: 0,
  date: "",
  time: "",
  qr_code_id: 0,
};

const PHONE_NUMBER_LENGTH = 10;

const formatPhone = (phone?: string) => phone?.replace(/\D/g, "") || "";

const LeadManagement = (props: LeadManagementProps): React.JSX.Element => {
  const navigate = useNavigate();
  const [leadData, setLead] = useRecoilState(leadAtom);
  const [plans, setPlans] = useRecoilState(plansAtom);

  const employee = useRecoilValue(employeeAtom);
  const setErrorBanner = useSetRecoilState(errorBannerAtom);
  
  const resetLead = useResetRecoilState(leadAtom);
  const { value: leadManagementMenuValue, loading: leadManagementMenuLoading } =
  useFeatureFlag("leadManagementMenu", false);
  const { value: dispositionsValue, loading: dispositionsLoading } =
  useFeatureFlag("dispositions", false);
  const { value: billingValue } =
  useFeatureFlag("leadBilling", false);
  const { value: schedulingValue } =
  useFeatureFlag("leadSchedule", false);

  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [step, setStep] = useState(FORM_STATES.personalInfo);
  const [isCustomer, setIsCustomer] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<FORM_STATES[]>([]);
  const [data, setData] = useState<Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo>(
    fieldsInitialData,
  );

  const [isMonthlyBilling, setIsMonthlyBilling] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [saveRequestText, setSaveRequestText] = useState("");
  const [apiError, setApiError] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState("Save");
  const [buttonAction, setButtonAction] = useState(() => () => save(data));
  const [isAmSelected, setIsAmSelected] = useState(false);
  const [isPmSelected, setIsPmSelected] = useState(false);

  const closerMenu = [
    ...setterMenu,
    {
      label: "Plan info",
      step: FORM_STATES.planInformation,
    },
  ];

  /* added prop for testing */
  if(billingValue || props.showBilling) {
    closerMenu.push({
      label: "Billing info",
      step: FORM_STATES.billingInformation,
    });
  }

  if(schedulingValue || props.showSchedule) {
    closerMenu.push({
      label: "Schedule",
      step: FORM_STATES.schedule,
    });
  }

  const menu = props.closer ? closerMenu : setterMenu;

  async function getLeadData () {
    setLoading(true);
    try {
      const leadResponse = await getLead(props.leadId);
      setLead({ ...fieldsInitialData, ...leadResponse.data });
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  async function getPlanData (postalCode: string) {
    setLoading(true);
    try {
      const planResponse = await getPlans(postalCode);
      setPlans(planResponse.data);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }

  const createNewLead = (lead: Lead) =>
    createLead({
      ...lead,
      phone_number: formatPhone(lead.phone_number),
    });


  const getChangedFields = (lead: Lead) => {
    const modifiedLead: Record<string, string> = {};

    for (const [key, value] of Object.entries(lead)) {
      if (leadData && value !== leadData[key]) {
        modifiedLead[key] = value;
      }
    }

    return modifiedLead;
  };

  const updateCurrentLead = (lead: Lead) => updateLead(props.leadId, lead);

  const save = async (lead: Lead) => {
    try {
      let newLeadData = null;

      setLoading(true);
      if (data.system_id) {
        const updatedFields = getChangedFields({
          ...lead,
          phone_number: formatPhone(lead.phone_number),
          qr_code_id: data.qr_code_id || 0
        });

        if (!Object.keys(updatedFields).length) {
          setSaveRequestText("No changes to save");
          nextStep();
          setLoading(false);
          return;
        }

        // Updates lead object with new lead data from response
        const response = await updateCurrentLead(updatedFields);
        if (response.status === 200) {
          setApiError(false);
          setSaveRequestText("The record has been successfully saved");
        }

        const updatedLead: Lead = { ...(response.data as Lead) };
        setLead(updatedLead as any);
        completeStep(FORM_STATES.personalInfo);
        nextStep();
      } else {
        newLeadData = await createNewLead(lead);
        navigate(`/inbound-setter/${newLeadData.data.system_id}`);
        completeStep(FORM_STATES.personalInfo);
        nextStep();
      }

      setLoading(false);
    } catch (e: any) {
      setApiError(true);
      setSaveRequestText(e.message);
      setLoading(false);
    }
  };

  const createNewCustomer = async (lead: Lead) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const planInfo =
          {
            "service_id": parseInt(lead.pr_service_id as unknown as string),
            "sold_by_id": (employee as unknown as Employee)?.masterEmployeeId || "0",
            "service_charge": parseFloat(lead.pr_service_charge as unknown as string),
            "initial_charge": parseFloat(lead.pr_initial_charge as unknown as string),
            "agreement_length": parseInt(lead.pr_agreement_length as unknown as string),
            "is_monthly_billing": isMonthlyBilling
          };
        const createResponse = await createCustomer(props.leadId, planInfo);
        if (createResponse.status === 200) {
          setApiError(false);
          setSaveRequestText("The customer has been successfully created");
          setLead(createResponse.data);
          completeStep(FORM_STATES.planInformation);
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      const message = e.response.data.errors[0]?.detail || e.message;
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const billingRequest = async (lead: Lead) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const billingInfo = {
            'customer_id': lead.pr_customer_id,
            'ach_cc': 'ach',
            'ach_billing_country': 'US',
            ...(lead.ach_first_name && {'ach_first_name': lead.ach_first_name}),
            ...(lead.ach_last_name && {'ach_last_name': lead.ach_last_name}),
            ...(lead.ach_billing_address && {'ach_billing_address': lead.ach_billing_address}),
            ...(lead.ach_billing_city && {'ach_billing_city': lead.ach_billing_city}),
            ...(lead.ach_billing_state && {'ach_billing_state': lead.ach_billing_state}),
            ...(lead.ach_billing_zip && {'ach_billing_zip': lead.ach_billing_zip}),
            ...(lead.bank_name && {'bank_name': lead.bank_name}),
            ...(lead.ach_routing_number && {'ach_routing_number': lead.ach_routing_number}),
            ...(lead.ach_account_number && {'ach_account_number': lead.ach_account_number}),
            ...(lead.ach_auto_pay && {'ach_auto_pay': lead.ach_auto_pay})
        };

        const createResponse = await billing(props.leadId, billingInfo);
        if (createResponse.status === 200) {
          setApiError(false);
          setSaveRequestText("The record has been successfully saved");          
          getLeadData();          
          completeStep(FORM_STATES.billingInformation);
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      const errors = e.response.data.errors;      
      const message = errors[0]?.detail || Object.values(errors)[0] || e.message;
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const sendAch = async (option: String) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const createResponse = await billing(props.leadId, { 'ach_cc': 'cc' , "credit_card_send_option": option });
        if (createResponse.status === 200) {
          setApiError(false);
          if(option === 'email') {
            setSaveRequestText("Email Sent");
          } else {
            setSaveRequestText("SMS Sent");
          }
        }
      }

      setLoading(false);
    } catch (e: any) {
      const message = e.response.data.errors[0]?.detail || e.message;
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const schedulingRequest = async () => {
    try {      
      setLoading(true);
      
      if (data.system_id) {
        const length = data?.additions.length;
        let i;
        for (i = 0; i < length; i++) {
          const item = data.additions[i];
          
          if(data?.additions[i].window === data.window) {
              const schedule_info = {
                spot_id: item?.spotID,
                appointment_type: 1,
                window: item.window,
                is_aro_spot: item.isAroSpot,
              };
              try {
                if (data.appointment.success === true) {
                  if(!data.email_address) {
                    setErrorBanner({
                      open: true,
                      message: 'Email has not been set. Please add an email on the Personal Info tab.'
                    });
                  }
                  const response = await rescheduling(props.leadId, schedule_info);
                  if (response.status === 200) {
                    setApiError(false);
                    setSaveRequestText("The appointment has been successfully scheduled");
                    getAptData();
                    completeStep(FORM_STATES.schedule);
                    break;
                  }
                } 
                if (data.appointment === false) {
                  if(!data.email_address) {
                    setErrorBanner({
                      open: true,
                      message: 'Email has not been set. Please add an email on the Personal Info tab.'
                    });
                  }
                  const response = await scheduling(props.leadId, schedule_info);
                  if (response.status === 200) {
                    setApiError(false);
                    setSaveRequestText("The appointment has been successfully scheduled");
                    setLead(response.data);
                    completeStep(FORM_STATES.schedule);
                    break;
                  }
                }
              } 
              catch (err: any) {
                if(i === length - 1) {
                  setApiError(true);
                  setSaveRequestText("We have difficulty to make a scheduling for this spot.");
                  setLoading(false);
                } else{
                  continue;
                }
              }
          }
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      console.log(e);
      setApiError(true);
      setSaveRequestText("Scheduling Error");  
      setLoading(false);    
    }
  };

  const nextStep = () =>{
    if(props.closer) {
      switch(step) {
        case FORM_STATES.personalInfo:
          goToStep(FORM_STATES.planInformation);
          break;
        case FORM_STATES.planInformation:
          if(billingValue) {
            goToStep(FORM_STATES.billingInformation);
          }
          break;
        case FORM_STATES.billingInformation:
          if(schedulingValue) {
            goToStep(FORM_STATES.schedule);
          }
          break;
      }
    }
  };

  const cancel = () => {
    setData(leadData || fieldsInitialData);
    setErrors([]);
  };

  const areRequiredFieldsFilled = (
    data: Lead & LeadBillingInfo & LeadSchedulingInfo,
    step: FORM_STATES,
  ) => {
    let isFilled;

    if (step === FORM_STATES.personalInfo) {
      isFilled = formatPhone(data.phone_number).length === PHONE_NUMBER_LENGTH;
      if (props.closer) {
        isFilled = isFilled &&
          data.name_first?.length !== 0 &&
          data.name_last?.length !== 0 &&
          data.address_line_1?.length !== 0 &&
          data.city?.length !== 0 &&
          data.postal_code?.length !== 0 &&
          data.province?.length !== 0;
      }
    }

    if (step === FORM_STATES.planInformation) {
      const validServiceType = (data?.pr_service_id !== 0);
      const validContractLength = (data?.pr_agreement_length !== 0);
      const validInitialPricing = (data?.pr_initial_charge !== 0);
      const validRecurringPricing = (data?.pr_service_charge !== 0);

      isFilled = validServiceType && validContractLength && validInitialPricing && validRecurringPricing;
    }

    if (step === FORM_STATES.billingInformation) {
      isFilled =
          data.ach_first_name !== undefined && data.ach_first_name?.length !== 0 &&
          data.ach_last_name !== undefined && data.ach_last_name?.length !== 0 &&
          data.ach_routing_number !== undefined && data.ach_routing_number?.length !== 0 &&
          data.ach_account_number !== undefined && data.ach_account_number?.length !== 0 &&          
          data.ach_billing_address !== undefined && data.ach_billing_address?.length !== 0 &&
          data.ach_billing_city !== undefined && data.ach_billing_city?.length !== 0 &&
          data.ach_billing_zip !== undefined && data.ach_billing_zip?.length !== 0 &&
          data.ach_billing_state !== undefined && data.ach_billing_state?.length !== 0 && 
          data.ach_auto_pay !== undefined;
    }

    if (step === FORM_STATES.schedule) {
      const filled =
        formatPhone(data.phone_number).length === PHONE_NUMBER_LENGTH;
      if (data.date) {
        isFilled = filled && data.date;
      } else {
        isFilled = filled;
      }
    }

    return isFilled;
  };

  const completeStep = (step: FORM_STATES) =>
    setCompletedSteps((prev) => (prev.includes(step) ? prev : [...prev, step]));

  const goToStep = (s: FORM_STATES) => setStep(s);

  const getStep = () => {
    switch (step) {
      case FORM_STATES.personalInfo:
        return {
          component: (
            <LeadMainInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
            />
          ),
          label: "Basic information",
          icon: "Clipboard",
        };
      case FORM_STATES.planInformation:
        return {
          component: (
            <PlanInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              isMonthlyBilling={isMonthlyBilling}
            />
          ),
          label: "Plan information",          
          icon: "Menu",
        };
      case FORM_STATES.billingInformation:
        return {
          component: (
            <BillingInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              onSend={(option: String) => sendAch(option)}
            />
          ),
          label: "Billing information",
          icon: "CreditCard",
        };
      case FORM_STATES.schedule:
        return {
          component: (
            <SchedulingInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              eventId={props.leadId}
              isAmSelected = {isAmSelected}
              isPmSelected = {isPmSelected}
              setIsAmSelected = {setIsAmSelected}
              setIsPmSelected = {setIsPmSelected}
              step={step}
              isCancelApt={isCancelApt}
              cancelApt={cancelApt}
            />
          ),
          label: "Scheduling information",
          icon: "Calendar",
        };
    }
  };

  const [isCancelApt, setIsCancelApt] = useState<any>();
  
  const cancelApt = async () => {
    try{
      setLoading(true);
      const aptRes = await cancelAppointement(props.leadId);
      if(aptRes.data) {
        setIsCancelApt(true);
        setApiError(false);
        setSaveRequestText(aptData.data?.message);
        setIsAmSelected(false);
        setIsPmSelected(false);
        setData({...data, appointment: null});
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [aptData, setAptData] = useState<any>();
    
  const getAptData = async () => {
    try {
      const aptRes = await getAppointement(props.leadId);
      if (aptRes.data) {
        setAptData(aptRes.data);
        aptRes.data?.data?.success === true && setIsCancelApt(false);
      }
    } catch (error) {
      setAptData({data: false});
      console.log(error);
    }
  };

  useEffect(() => {
    getAptData();
  }, [step]);

  useEffect(() => {
    if(step == FORM_STATES.schedule) {
      setData({...data, appointment: aptData?.data});
    }
  }, [aptData]);
  
  
  useEffect(() => {
    if (props.closer) {
      if (step === FORM_STATES.planInformation && !isCustomer) {
        setButtonLabel("Create Customer");
        setButtonAction(() => () => createNewCustomer(data));
      } else if (step === FORM_STATES.billingInformation || step === FORM_STATES.schedule) {
        setButtonLabel("Save");
        if(step === FORM_STATES.billingInformation) {
          setButtonAction(() => () => billingRequest(data));
        }
        if(step === FORM_STATES.schedule) {
          setButtonAction(() => () => schedulingRequest());
        }
      } else {
        setButtonLabel("Next");
        setButtonAction(() => () => save(data));
      }
    } else {
      setButtonAction(() => () => save(data));
    }
  }, [step, data]);

  useEffect(() => {
    if(data['pr_payment_profiles'] && data['pr_payment_profiles'].length) {
      const paymentProfile = data['pr_payment_profiles'][0];
      setLead({
       ...data,
       ...paymentProfile
      });
    }

  }, [data['pr_payment_profiles']]);

  useEffect(() => {
    cancel();
  }, [step]);

  useEffect(() => {
    if (leadData) {
      let newLeadData: any = leadData;
      newLeadData = {...newLeadData, appointment: data?.appointment};
      setData(newLeadData);

      if(props.closer) {
        getPlanData(leadData['postal_code']);

        if(areRequiredFieldsFilled(leadData, FORM_STATES.personalInfo)) {
          completeStep(FORM_STATES.personalInfo);
        }

        if (leadData['pr_customer_id'] !== 0) {
          setIsCustomer(true);
          completeStep(FORM_STATES.personalInfo);
          completeStep(FORM_STATES.planInformation);                    
        }

        // At least 1 payment profile was made
        if (leadData['pr_payment_profiles'] && leadData['pr_payment_profiles'][0]) {
          setIsCustomer(true);
          completeStep(FORM_STATES.billingInformation);
        }

        if (leadData['pr_appointment'] && leadData['pr_appointment']['appointmentId']) {
          setIsCustomer(true);
          completeStep(FORM_STATES.schedule);
        }
      }
    } else {
      let temp: any = fieldsInitialData;
      temp.appointment = data.appointment;
      setData(temp);
    }
  }, [leadData]);

  useEffect(() => {
    if (props.leadId) {
      getLeadData();      
    } else {
      resetLead();
    }
  }, [props.leadId]);

  useEffect(() => {    
    let leadMonthly = false;
    let planMonthly = false;
    if (leadData) {
      leadMonthly = leadData['pr_is_monthly_billing'];
    }

    const firstPlanKey = Object.keys(plans)[0];
    if (firstPlanKey) {      
      planMonthly = plans[firstPlanKey as keyof typeof plans]['isMonthlyBilling'];
    }

    setIsMonthlyBilling(leadMonthly || planMonthly);
  }, [leadData, plans]);

  return (
    <>
      <LeadManagementStyled id="main-container" data-testid="lead-management">
        {loading && <Spinner overlay={true} />}
        <ErrorBanner />
        <LeadBar />
        <FlexContainer
          className={`lead-page ${props.closer ? "_closer" : "_setter"}`}
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <FlexContainer column alignItems="stretch">
            <SectionHeader
              label={getStep()?.label || ""}
              icon={getStep()?.icon || ""}
              monthlyBanner={isMonthlyBilling}
            />
            <FlexContainer
              className="lead-page__section__wrapper"
              alignItems="stretch"
            >
              {/* added prop for testing */}
              {props.closer && (
                ((leadManagementMenuValue && !leadManagementMenuLoading) || props.showMenu) &&
                <div className="lead-page__menu">
                  <LeadManagementMenu
                    completedSteps={completedSteps}
                    menuList={menu}
                    step={step}
                    onChange={goToStep}
                  />
                </div>
              )}

              <div className="lead-page__step">{getStep()?.component}</div>
            </FlexContainer>
            <LeadActionButtonsStyles>
              <FlexContainer justifyContent="space-between" alignItems="center">
                <Notification
                  error={apiError}
                  text={saveRequestText}
                  setText={setSaveRequestText}
                  timeout={true}
                />
                <FlexContainer justifyContent="space-between">
                  <ButtonSecondary onClick={cancel}>Cancel</ButtonSecondary>

                  {
                    step === 3 ? 
                    <>
                      <ButtonPrimary style={{marginLeft: "8px"}}
                        disabled={!!errors.length || !areRequiredFieldsFilled(data, step) || (isAmSelected === false && isPmSelected === false)}
                        onClick={buttonAction}
                      >
                        {buttonLabel}
                      </ButtonPrimary>
                      {/* {isCancelApt === false && <ButtonSecondary onClick={cancelApt} style={{margin: "8px"}}>Cancel Appointment</ButtonSecondary>} */}
                    </> : 
                      <ButtonPrimary
                        disabled={!!errors.length || !areRequiredFieldsFilled(data, step)}
                        onClick={buttonAction}
                      >
                        {buttonLabel}
                      </ButtonPrimary>   
                  }
                </FlexContainer>
              </FlexContainer>
            </LeadActionButtonsStyles>
          </FlexContainer>
          <Notes />
          {(dispositionsValue && !dispositionsLoading) && <Dispositions />}
        </FlexContainer>
      </LeadManagementStyled>
    </>
  );
};

export default LeadManagement;
