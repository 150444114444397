import * as React from "react";

import { MenuRadioItemStyle, MenuRadioButton } from "./MenuRadioItem.Style";
import { FlexContainer } from "./../Flex/Flex.Styles";
import { TextStyled } from "./../Text/Text.Styles";
import {
  graceGray600,
  gray400,
  bluePrimary,
  baseGray200,
} from "./../../styleConstants";

interface MenuRadioItemProps {
  label: string;
  isCompleted?: boolean;
  isActive?: boolean;
  onClick: () => void;
  isDisabled?: boolean;
}

const MenuRadioItem = (props: MenuRadioItemProps): React.JSX.Element => {
  const onClick = () => {
    !props.isDisabled && !props.isActive && props.onClick();
  };

  return (
    <MenuRadioItemStyle onClick={onClick} data-testid="menu-radio-item">
      <MenuRadioButton
        isActive={props.isActive}
        isCompleted={props.isCompleted}
      >
        <div className="menu-ralio-item__inner"></div>
        <div className="menu-ralio-item__check"></div>
      </MenuRadioButton>
      <FlexContainer column alignItems="start">
        <TextStyled
          fontSize="md"
          fontWeight="thin"
          color={
            props.isActive
              ? bluePrimary
              : props.isDisabled
              ? baseGray200
              : graceGray600
          }
        >
          {props.label}
        </TextStyled>
        <TextStyled
          fontWeight="thin"
          color={props.isDisabled ? baseGray200 : gray400}
        >
          {props.isCompleted ? "Complete" : "Incomplete"}
        </TextStyled>
      </FlexContainer>
    </MenuRadioItemStyle>
  );
};

export default MenuRadioItem;
